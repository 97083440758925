import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExpLibResponse, Industry} from '@app/core/models/categorization-types';
import { TitleService } from '@app/core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '@app/security/session.service';
import { CategorizationService } from '@app/core/services/categorization.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { LoggerService } from '@app/core/services/logger.service';

@Component({
    selector: 'rn-industry-add-edit',
    templateUrl: './industry-add-edit.component.html',
    styleUrls: ['./industry-add-edit.component.scss'],
    standalone: false
})
export class IndustryAddEditComponent implements OnInit {
  clientId: string;
  industryInfoGroup: UntypedFormGroup;
  industry: Industry = {
    id: '',
    title: {
      'en-us': ''
    }
  };
  isNewIndustry: boolean = false;
  industryIdToGet: string = '';
  isLoaded: boolean = false;
  hasError: boolean = false;
  constructor(
    private titleService: TitleService, 
    private activatedRoute: ActivatedRoute, 
    private sessionService: SessionService, 
    private categorizationService: CategorizationService,
    private router: Router, 
  ) {}
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.isNewIndustry = this.activatedRoute.snapshot.data["isNewIndustry"];
    this.industryInfoGroup = new UntypedFormGroup({
      industry_id: new UntypedFormControl(
        this.industry.id || '',
        Validators.required
      ),
      industry_name: new UntypedFormControl(
        this.industry.title['en-us'] || '',
        Validators.required
      ),
    });
    if (this.isNewIndustry) {
      this.titleService.activate('Add Industry');
      this.isLoaded = true;
    } else {
      this.titleService.activate('Edit Industry');
      this.industryIdToGet = this.activatedRoute.snapshot.params["industry-id"];
      this.getIndustry(this.clientId, this.industryIdToGet);
    }
  }

  getIndustry(clientId: string, industryId: string): void {
    this.categorizationService.getIndustry(clientId, industryId)
      .subscribe(
        (industry: ExpLibResponse<Industry>) => {
          this.industry = industry.data;
          if (!this.industry) {
            this.processIndustryError(new Error('Retrieved industry is null.'));
          } else {
            this.industryIdCtl.setValue(this.industry.id);
            this.industryNameCtl.setValue(this.industry.title['en-us']);
          }
          this.isLoaded = true;
      }, (error) => {
        this.processIndustryError(error);
        this.isLoaded = true;
      });
  }

  get industryIdCtl(): UntypedFormControl {
    return <UntypedFormControl>this.industryInfoGroup.controls['industry_id'];
  }

  get industryNameCtl(): UntypedFormControl {
    return <UntypedFormControl>this.industryInfoGroup.controls['industry_name'];
  }

  cancelAndClose(): void {
    this.router.navigateByUrl(`/experience-library/admin`);
  }

  saveNewIndustry(industry: Industry) {
    this.categorizationService.addIndustry(this.clientId, industry)
    .subscribe(
      (addedIndustry:ExpLibResponse<Industry>) => {
        if(addedIndustry.data.id) {
          this.router.navigateByUrl(`/experience-library/admin`);
        };
      }, (error) => {
        this.processIndustryError(error);
    });
  }

  updateIndustry(industry: Industry) {
    this.categorizationService.updateIndustry(this.clientId, industry.id, industry)
    .subscribe(
      (addedIndustry: ExpLibResponse<Industry>) => {
        if (addedIndustry.data.id) {
          this.router.navigateByUrl(`/experience-library/admin`);
        };
      }, (error) => {
        this.processIndustryError(error);
    });
  }

  saveAndClose(): void {
    const industryData = this.industryInfoGroup.getRawValue();
    if (this.isNewIndustry) {
      this.industry.id = industryData.industry_id;
      this.industry.title['en-us'] = industryData.industry_name;
      this.saveNewIndustry(this.industry);
    } else {
      this.industry.title['en-us'] = industryData.industry_name;
      delete this.industry.created_at;
      delete this.industry.updated_at;
      this.updateIndustry(this.industry);
    }
  }

  processIndustryError(error): void {
    this.isLoaded = true;
    this.hasError = true;
    this.messageDialog.showMessage('An error occured with your request, please try again later.');
    LoggerService.log('industry-add.component', `add/edit industry for clientId ${this.clientId} error: ${JSON.stringify(error)}`);
  }
}
