import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from '@app/core/services/title.service';

@Component({
    selector: 'app-all-journeys',
    templateUrl: './all-journeys.component.html',
    styleUrls: ['./all-journeys.component.scss'],
    standalone: false
})
export class AllJourneysComponent implements OnInit, OnDestroy {
  primaryTitle: string = 'All Experiences';

  constructor(private titleService: TitleService) { }

  ngOnInit() {
    this.titleService.activate(this.primaryTitle);
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }
}
