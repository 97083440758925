import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CompanyType,
  ExpLibResponse,
  Outcome,
} from '@app/core/models/categorization-types';
import {
  CategorizationService,
  OptionsMapNode,
} from '@app/core/services/categorization.service';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'rn-outcome-add-edit',
    templateUrl: './outcome-add-edit.component.html',
    styleUrls: ['./outcome-add-edit.component.scss'],
    standalone: false
})
export class OutcomeAddEditComponent implements OnInit {
  @ViewChild('updateErrorDialog') updateErrorDialog: MessageDialogComponent;
  @ViewChild('loadErrorDialog') loadErrorDialog: MessageDialogComponent;
  clientId: string;
  outcomeId: string | undefined;
  companyTypeOptions: CompanyType[];
  form: UntypedFormGroup;
  optionsMap: OptionsMapNode[];
  idValidator: ValidatorFn;

  constructor(
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private categorizationService: CategorizationService
  ) {}

  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.outcomeId = this.activatedRoute.snapshot.params['outcome-id'];

    this.categorizationService.getOptionsMap(this.clientId).subscribe(
      (result) => {
        this.optionsMap = result;
      },
      (error) => {
        this.loadErrorDialog.showMessage(
          'The system has encountered an error. Please try again later.'
        );
      }
    );

    this.categorizationService
      .getCompanyTypes(this.clientId)
      .pipe(take(1))
      .subscribe(
        (response: ExpLibResponse<CompanyType[]>) => {
          this.companyTypeOptions = response.data;
        },
        (error) => {
          this.loadErrorDialog.showMessage(
            'The system has encountered an error. Please try again later.'
          );
        }
      );

    if (this.isNew()) {
      this.setupNew();
    } else {
      this.setupExisting();
    }
  }

  get outcomeIdCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('id');
  }

  get outcomeNameCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('name');
  }

  get ancestorPathsCtl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('ancestor_paths');
  }

  validateId(): ValidatorFn {
    return (control) => {
      if (!this.optionsMap) {
        return null;
      }

      const idValue = (control.value as string).toLocaleLowerCase();

      // check if any of the existing outcomes have the same ID as the one that
      // the user typed in (case insensitive)
      const collision = this.optionsMap.some((industry) =>
        industry.children?.some((companyType) =>
          companyType.children?.some(
            (outcome) => outcome.id.toLocaleLowerCase() === idValue
          )
        )
      );

      return collision
        ? { custom: { message: 'ID must be unique across all outcomes' } }
        : null;
    };
  }

  validateName(currentlyEditing?: Outcome): ValidatorFn {
    return (control) => {
      if (!this.optionsMap) {
        return null;
      }

      if (!this.form) {
        return null;
      }

      if (!this.ancestorPathsCtl || !this.outcomeNameCtl) {
        return null;
      }

      // this validator is applied to both the name and ancestor controls so
      // that it re-evaluates when either is changed, but we need to know which
      // control is sending us this update
      let isNameCtl = false;
      if (control === this.outcomeNameCtl) {
        isNameCtl = true;
      }

      const nameValue = (
        (isNameCtl ? control : this.outcomeNameCtl).value as string
      ).toLocaleLowerCase();
      const selectedAncestorPaths: string[] = this.ancestorPathsCtl.value;

      // based on the ancestors selected, check their outcomes' names and see if
      // any match (collide with) the name that was typed in the outcome field
      // (case-insensitive)
      // 1. based on selected ancestors paths, find all the industries that are
      // selected
      const selectedIndustries = this.optionsMap.filter((industry) =>
        selectedAncestorPaths.some((path) => path.split('.')[0] === industry.id)
      );

      const collision = selectedIndustries.some((industry) => {
        // 2. based on the selected industries, find all the company types that
        // are selected within
        const selectedCompanyTypes = industry.children?.filter((companyType) =>
          selectedAncestorPaths.some((path) => {
            const parts = path.split('.');
            return parts[0] === industry.id && parts[1] === companyType.id;
          })
        );

        // 3. based on outcomes in the selected company types, compare those
        // names (excluding the current one, if applicable) and see if the name
        // text field value is already present (indicating a collision)
        return selectedCompanyTypes.some((companyType) =>
          companyType.children?.some((outcome) => {
            return (
              outcome.id !== currentlyEditing?.id &&
              outcome.name.toLocaleLowerCase() === nameValue
            );
          })
        );
      });

      if (collision) {
        const error = {
          custom: { message: 'Name must be unique within shared ancestors' },
        };

        if (isNameCtl) {
          return error;
        } else {
          this.outcomeNameCtl.setErrors(error);
          return null;
        }
      } else if (!isNameCtl) {
        this.outcomeNameCtl.setErrors(null);
      }
    };
  }

  isNew(): boolean {
    return this.outcomeId === undefined;
  }

  close(): void {
    this.router.navigateByUrl('/experience-library/admin?tab=outcome-tab');
  }

  ancestorPath(industryId: string, companyTypeId: string): string {
    return `${industryId}.${companyTypeId}`;
  }

  saveAndClose(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    let request: Observable<ExpLibResponse<Outcome>>;

    const newOutcome: Outcome = {
      title: {
        'en-us': this.form.get('name').value,
      },
      id: this.form.get('id').value,
      ancestor_paths: this.form.get('ancestor_paths').value,
    };

    if (this.isNew()) {
      request = this.categorizationService.addOutcome(
        this.clientId,
        newOutcome
      );
    } else {
      request = this.categorizationService.updateOutcome(
        this.clientId,
        newOutcome.id,
        newOutcome
      );
    }

    request.subscribe(
      (result) => {
        this.close();
      },
      (error) => {
        this.updateErrorDialog.showMessage(
          `There was an error ${
            this.isNew() ? 'creating' : 'updating'
          } this outcome.  Please try again.`
        );
      }
    );
  }

  isReady(): boolean {
    return this.form !== undefined;
  }

  private setupNew(): void {
    this.titleService.activate('Add Outcome');
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl('', [Validators.required, this.validateId()]),
      name: new UntypedFormControl('', [Validators.required, this.validateName()]),
      ancestor_paths: new UntypedFormControl(
        [],
        [Validators.required, this.validateName()]
      ),
    });
  }

  private setupExisting(): void {
    this.titleService.activate('Edit Outcome');
    this.categorizationService
      .getOutcome(this.clientId, this.outcomeId)
      .pipe(take(1))
      .subscribe(
        (outcome: ExpLibResponse<Outcome>) => {
          this.form = new UntypedFormGroup({
            id: new UntypedFormControl(outcome.data.id), // not required because its disabled
            name: new UntypedFormControl(outcome.data.title['en-us'], [
              Validators.required,
              this.validateName(outcome.data),
            ]),
            ancestor_paths: new UntypedFormControl(outcome.data.ancestor_paths, [
              Validators.required,
              this.validateName(outcome.data),
            ]),
          });
          this.outcomeIdCtl.disable();
        },
        (error) => {
          this.loadErrorDialog.showMessage(
            'There was an error loading this outcome. Please try again.'
          );
        }
      );
  }
}
