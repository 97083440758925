import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { JourneyService } from '@app/core/services/journey.service';
import { MessageClass } from '@app/core/models/message';
import { Renumberer } from '@app/core/utils/renumberer';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';
import { SessionService } from '@app/security/session.service';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';

@Component({
    selector: 'journey-step-preview',
    templateUrl: './journey-step-preview.component.html',
    styleUrls: ['./journey-step-preview.component.scss'],
    standalone: false
})
export class JourneyStepPreviewComponent implements OnInit {
  @Input() selectionType: string = null;
  @Input() copyType: string = null;
  @Input() copyMsgParams: any;
  @Input() step: any;
  @ViewChild('messageDialogWithKickback', { static: true })
  messageDialogWithKickback: MessageDialogComponent;

  showSmsByDefault: boolean;
  targetJourneyId: string;
  choiceToggle: boolean = false;

  constructor(
    private journeyService: JourneyService,
    private twoWayService: TwoWaySharedService,
    private sessionService: SessionService,
    public newFeed: NewFeedTransitionService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.showSmsByDefault =
      this.sessionService.getCurrentUsersClient().show_sms_by_default;
  }

  copyMsgToJourney() {
    const that = this;
    this.journeyService
      .getJourneyById(this.copyMsgParams['journeyId'])
      .subscribe((journeyRecord) => {
        const targetMsg = journeyRecord.draft.components.find(
          (msg) => msg['name'] === that.copyMsgParams.messageId,
        );
        let newMsgPosition = journeyRecord.draft.components.indexOf(targetMsg);
        let precedingMsg = journeyRecord.draft.components.find(
          (msg) => msg['to'] === that.copyMsgParams.messageId,
        );
        const newMsg = Object.assign(
          new MessageClass({ showSmsByDefault: this.showSmsByDefault }),
          that.step,
        );
        const newMsgActions = newMsg.wire.actions;
        const newResponsMsgs = [];

        newMsg.name = newMsg.createGuid();
        newMsg.type = targetMsg.type;
        newMsg.to = targetMsg.to;
        newMsg.step = targetMsg.step;

        if (newMsg.type === 'MessageSender' && newMsg.parent) {
          newMsg.parent = null;
          newMsg.sms.is_shown = 'true';
        }

        if (newMsg.type === 'MessageResponse') {
          newMsg.sms.is_shown = 'false';
        }

        if (targetMsg.type === 'MessageResponse') {
          newMsg.parent = targetMsg.parent;
          precedingMsg = journeyRecord.draft.components.find(
            (msg) => msg['name'] === targetMsg.parent,
          );
          precedingMsg.wire.actions.find(
            (action) => action['value'] === targetMsg.name,
          ).value = newMsg.name;
        } else {
          precedingMsg.to = newMsg.name;
        }

        for (let i = 0; i < newMsgActions.length; i++) {
          newMsgActions[i].id = newMsg.createGuid();
          if (newMsgActions[i].type === 'message') {
            const newResponse = new MessageClass({
              showSmsByDefault: this.showSmsByDefault,
            });
            newResponse.parent = newMsg.name;
            newResponse.type = 'MessageResponse';
            newMsgActions[i].value = newResponse.name;
            newResponsMsgs.push(newResponse);
          }
        }

        journeyRecord.draft.components.splice(newMsgPosition, 1, newMsg);

        newResponsMsgs.forEach((msg) => {
          journeyRecord.draft.components.splice(++newMsgPosition, 0, msg);
        });

        const renumber = new Renumberer(journeyRecord);
        renumber.renumber(journeyRecord.draft.components[0], 0);

        this.journeyService
          .updateJourney(this.copyMsgParams['journeyId'], journeyRecord)
          .subscribe(
            () => {
              const route =
                'cx-builder/experience-builder/' +
                this.copyMsgParams['journeyId'];
              this.router.navigateByUrl(route);
            },
            (error) => {
              const errBody = error;
              if (
                error.status === 409 &&
                errBody.response &&
                errBody.response.indexOf('Mismatch') > -1
              ) {
                this.messageDialogWithKickback.showMessage(
                  'The experience could not be promoted because a new version is available.',
                );
              } else if (
                error.status === 409 &&
                errBody.message &&
                errBody.message
              ) {
                this.messageDialogWithKickback.showMessage(error.message);
              } else if (error.status !== 401) {
                this.messageDialogWithKickback.showMessage(
                  `Oops...The experience could not be updated: ${error.error.response}`,
                );
              }
            },
          );
      });
  }

  navigateBackToJourneyBuilder() {
    this.router.navigateByUrl(
      `/cx-builder/experience-builder/${this.targetJourneyId}`,
    );
  }

  toggleCopyChoice(event: any) {
    event.stopPropagation();

    if (this.copyType === 'message') {
      this.choiceToggle = !this.choiceToggle;
    }
  }

  showAction(): boolean {
    return this.twoWayService.showTwoWay();
  }

}
