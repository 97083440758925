import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Journey } from '@app/core/models/journey';
import { ContentInstance } from '../list-options';
import { JourneySelectService } from '@app/core/services/journey-select.service';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { JourneyService } from '@app/core/services/journey.service';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { ExperienceLibraryService } from '@app/core/services/experience-library.service';
import { take } from 'rxjs/operators';
import { JourneyListUtils } from '../journey-list-utils';
import { FallbackJourneyInfo } from '@app/core/models/fallback-journey-info';

@Component({
    selector: 'journey-summary',
    templateUrl: './journey-summary.component.html',
    styleUrls: ['./journey-summary.component.scss'],
    standalone: false
})

export class JourneySummaryComponent implements OnInit {
  @Input() journeyRecord: Journey;
  @Input() expanded: boolean = false;
  @Input() showActions: boolean = false;
  @Input() draftsSuppressed: boolean = false; // indicates whether to show the large "complete" text
  @Input() index: number;

  @Input() selectionType: string; // "CSR" (CSR Config & CSR Onboarding), "copy" (CX builder - copy Journey OR message) - never "launch" in journeyList/Summary!!
  @Input() launcherMode: boolean = false;

  @Input() showCheckbox: boolean = false;
  @Input() isCheckedJourney: boolean = false;

  @Input() showCopyBtn: boolean = false;

  @Input() version: string = 'live';
  @Input() showVersionToggle: boolean = false;
  @Input() isSelectedVersion: boolean = false;

  @Output() exportExperienceError: EventEmitter<{journey: Journey, errorMessage: string}> = new EventEmitter<{journey: Journey, errorMessage: string}>();
  @Output() copyJourneyRequested: EventEmitter<Journey> = new EventEmitter<Journey>();
  @Output() journeyChecked: EventEmitter<object> = new EventEmitter<object>();
  @Output() versionToggled: EventEmitter<ContentInstance> = new EventEmitter<ContentInstance>();
  @Output() fallbackJourneySelected: EventEmitter<object> = new EventEmitter<Journey>();

  permissions = Permissions;
  busy = false;

  isOutOfBoxExperienceEnabled: boolean = false;
  journeyListUtils = new JourneyListUtils();
  outcome_names: string = '';
  experience_type_names: string = '';

  constructor(
    public activeRoute: ActivatedRoute,
    private journeySelectService: JourneySelectService,
    private journeyService: JourneyService,
    public permissionService: PermissionService,
    private featureService: FeatureService,
    private experienceLibraryService: ExperienceLibraryService) {
  }

  get journeyHasClientTriggerId(): boolean {
    return this.journeyRecord.latestContent.components.some((component) => {
      return component.type === 'APITrigger' && component.client_trigger_id !== undefined;
    });
  }

  get journeyContent() {
    return this.journeyRecord[this.version];
  }

  ngOnInit() {
    this.journeySelectService.journeysCleared.subscribe(() => {
      this.isCheckedJourney = false;
    });

    this.isOutOfBoxExperienceEnabled = this.featureService.checkFlag(FeatureFlags.experience_library);
    this.journeyListUtils.isOutOfBoxExperienceEnabled = this.isOutOfBoxExperienceEnabled;

    if (this.isOutOfBoxExperienceEnabled) {
      const messageComponents = this.journeyContent.components.filter(
        (m) =>
          m.type === 'MessageSender' &&
          m.outcome_name !== undefined &&
          m.experience_type_name !== undefined
      );

      if (messageComponents.length > 0) {
        const uniqueOutcomeNames = [
          ...new Set(messageComponents.map((m) => m.outcome_name))
        ];

        const uniqueExperienceTypeNames = [
          ...new Set(messageComponents.map((m) => m.experience_type_name))
        ];

        this.outcome_names = uniqueOutcomeNames.join(', ');
        this.experience_type_names = uniqueExperienceTypeNames.join(', ');
      }
    }
  }

  copyJourney(journey: Journey, event: Event): void {
    event.stopPropagation();
    this.copyJourneyRequested.emit(journey);
  }

  exportExperience(journey: Journey, event: Event): void {
    event.stopPropagation();
    this.experienceLibraryService.getExperienceExport(journey.client_id, journey.id)
      .pipe(take(1))
      .subscribe({
        next: async (result) => {
          if (result.hasOwnProperty('data')) {
            const elem = window.document.createElement('a');
            elem.href = 'data:application/zip;base64,' + result.data;
            elem.download = `${journey.client_id}_${journey.id}.zip`;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          } else {
            this.exportExperienceError.emit({ journey , errorMessage: "Response does not contain data"});
          }
        },
        error: (error) => {
          this.exportExperienceError.emit({ journey , errorMessage: error.error.error});
        }
      });
  }

  archiveJourney(journey: Journey, event: Event): void {
    event.stopPropagation();
    this.busy = true;
    this.journeyService.archiveJourney(journey)
      .subscribe(
        () => {
          this.journeyRecord.deleted = !this.journeyRecord.deleted;
          this.busy = false;
        },
        () => {
          this.busy = false;
        }
      );
  }

  restoreJourney(journey: Journey, event: Event): void {
    event.stopPropagation();
    this.busy = true;
    this.journeyService.restoreJourney(journey)
      .subscribe(
        () => {
          this.journeyRecord.deleted = !this.journeyRecord.deleted;
          this.busy = false;
        },
        () => {
          this.busy = false;
        }
      );
  }

  journeyHasBeenChecked(event): void {
    event.preventDefault();
    this.isCheckedJourney = !this.isCheckedJourney;

    const obj = {
      journeyID: this.journeyRecord.id,
      action: this.isCheckedJourney ? 'add' : 'remove'
    };

    this.journeyChecked.emit(obj);
  }

  versionRadioToggled(event: any): void {
    event.stopPropagation();
    const contentInstance = this.isDraft() ? ContentInstance.draft : ContentInstance.live;
    this.toggleVersion();
    this.versionToggled.emit(contentInstance);
  }

  toggleVersion(): void {
    this.isSelectedVersion = !this.isSelectedVersion;
  }

  selectFallbackJourney(journey: Journey): void {
    const triggerId = journey.live.components.find(x => x.type === 'APITrigger').name;

    const journeyInfo: FallbackJourneyInfo = {'id': journey.id, 'name': journey.live.name, 'triggerId': triggerId};

    this.fallbackJourneySelected.emit(journeyInfo);
  }

  stopProp(e: any): void {
    e.stopPropagation();
  }

  shouldSuppressDrafts(): boolean {
    return this.draftsSuppressed || this.selectionType === 'customer-list-launch';
  }

  previewJourney(): void {
    this.journeyService.previewJourney(this.journeyRecord);
  }

  journeyPreviewable(): boolean {
    return this.journeyService.journeyPreviewable(this.journeyRecord);
  }

  isDraft(): boolean {
    return this.version === 'draft';
  }

  isLive(): boolean {
    return this.version === 'live';
  }

  isAllJourneysList(): boolean {
    return this.selectionType === null && !this.launcherMode;
  }

  showExportExperienceButton(journeyRecord: Journey): boolean {
    const exportPermission = this.permissionService.checkPermissions(this.permissions.exp_lib_experience_export);
    const newExperienceTransportEnabled = this.featureService.checkFlag(FeatureFlags.new_experience_transport);
    const journeyNotArchived = !journeyRecord.deleted;
    return exportPermission && newExperienceTransportEnabled && journeyNotArchived;
  }

  disableExportExperienceButton(journeyRecord: Journey) {
    return !journeyRecord.live;
  }

  showUnarchiveButton(journeyRecord: Journey) {
    return this.permissionService.checkPermissions(this.permissions.journey_delete) && journeyRecord.deleted;
  }

  showSendExperienceButton(journeyRecord: Journey) {
    return this.permissionService.checkPermissions(this.permissions.journey_trigger) && !journeyRecord.deleted;
  }

  showArchiveButton(journeyRecord: Journey) {
    return this.permissionService.checkPermissions(this.permissions.journey_delete) && !journeyRecord.deleted;
  }

  showEditExperienceButton() {
    return this.permissionService.checkPermissions(this.permissions.journey_edit);
  }
}
