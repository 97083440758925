import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { JourneySummaryComponent } from '@app/journey-list/journey-summary/journey-summary.component';
import { ContentInstance, DisplayOption } from '../list-options';
import { SortableComponent } from '@app/core/sortable-component';
import { PageManager } from '@app/core/utils/page-manager';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { Client } from '@app/core/models/client';
import { Journey } from '@app/core/models/journey';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { Ordering } from '@app/core/utils/ordering';
import { Filter, SearchCriteria } from '@app/core/utils/search-criteria';
import { SearchableField } from '@app/shared/search-bar/search-bar.component';
import { JourneyService } from '@app/core/services/journey.service';
import { JourneySelectService } from '@app/core/services/journey-select.service';
import { ClientService } from '@app/core/services/client.service';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { CsrJourneysModalService } from '@app/core/services/csr-journeys-modal.service';
import { FeatureService } from '@app/core/services/feature.service';
import * as _ from 'lodash';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { JourneyListUtils } from '../journey-list-utils';
import { FallbackJourneyInfo } from '@app/core/models/fallback-journey-info';
import { Observable, Subscription } from 'rxjs';


@Component({
    selector: 'app-journey-list',
    templateUrl: './journey-list.component.html',
    styleUrls: ['./journey-list.component.scss'],
    standalone: false
})

/*
  For future reference, this component exists in 6 places, with these conditions:

  1. AllJourneys
  2. LauncherListComponent
    - this.launcherMode === true
    - drafts suppressed, shows a 'launch' action button
  3. Client Config
    3a. PG - OnboardingComponent (aka CSR Fallback Journey Select)
      - this.selectionType === 'CSR' && this.showCheckboxes === false
      - drafts suppressed, shows a 'select' action button
    3b. Client- ClientOnboardingAndFeatureComponent
      - this.selectionType === 'CSR' && this.showCheckboxes === true
      - drafts suppressed, shows multi-select checkboxes & submit button
  4. CxBuilder
    4a. message copy:
      - this.selectionType === 'copy' && this.copyType === 'message'
      - click on message, and then on a button to select a message
    4b. journey copy:
      - this.selectionType === 'copy' && this.copyType === 'journey'
      - shows 'copy' action button
*/
export class JourneyListComponent extends SortableComponent implements OnInit {
  @Input() listTitle: string;
  @Input() copy: boolean = false;
  @Input() copyType: string = null;
  @Input() selectionType: string = null;
  @Input() launcherMode: boolean = false;
  @Input() showCheckboxes: boolean = false;
  @Input() includeSmsOnlyMessages: boolean = true;
  @Input() csrFilter: boolean = false;
  @Input() limit: number = 20;

  // this just passes an event from grandchild to grandparent.  Should be replaced with a service or something.
  @Output() fallbackJourneySelected: EventEmitter<object> = new EventEmitter<object>();

  @ViewChild(MessageDialogComponent, {static: true}) messageDialog: MessageDialogComponent;
  @ViewChild('liveSummary') liveSummary: JourneySummaryComponent;
  @ViewChild('draftSummary') draftSummary: JourneySummaryComponent;

  client: Client;
  csrJourneys: string[];
  journeys: Journey[] = [];
  loaded = false;
  displayJourney = {};
  messageView = 'normal';
  showGrid = 'false;';
  showArchived = false;
  showOptions = false;
  csrSelectParams: any;
  permissions = Permissions;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;
  offset = 0;
  ordering: Ordering;
  selectedVersion: ContentInstance;
  displayOption = DisplayOption;
  searchCriteria = new SearchCriteria();
  searchableFields = [
    new SearchableField('Experience Name', 'name'),
    new SearchableField('Created By', 'created_by_name'),
    new SearchableField('Product Group Wire Display', 'product_group_name'),
    new SearchableField('Product Group Internal Name', 'product_group_internal_name'),
    new SearchableField('Campaign', 'campaign'),
    new SearchableField('Description', 'description'),
    new SearchableField('ID', 'id'),
    new SearchableField('Nickname', 'components.nickname'),
    new SearchableField('Client Trigger ID', 'components.client_trigger_id'),
    new SearchableField('SMS Text', 'components.sms.text'),
    new SearchableField('Message Text', 'components.wire.text'),
  ];
  subscriptionArray = [];
  pageManager: PageManager = new PageManager();
  isOutOfBoxExperienceEnabled: boolean = false;
  copyMsgParams: any;

  journeyListUtils = new JourneyListUtils();

  constructor(
    private clientService: ClientService,
    private journeyService: JourneyService,
    private journeySelectService: JourneySelectService,
    private router: Router,
    private location: Location,
    public activeRoute: ActivatedRoute,
    public permissionService: PermissionService,
    private csrJourneysModalService: CsrJourneysModalService,
    private featureService: FeatureService) {
    super();
  }

  get detailsDisplayOption() {
    if (this.selectedVersion === ContentInstance.draft) {
      return DisplayOption.draft;
    } else if (this.selectedVersion === ContentInstance.live) {
      return DisplayOption.live;
    } else {
      return DisplayOption.preferLiveToDraft;
    }
  }

  ngOnInit() {
    if (this.selectionType === 'copy') {
      this.activeRoute.params.subscribe(params => {
        this.copyMsgParams = params;
      });
    } else if (this.selectionType === 'CSR') {
      this.activeRoute.params.subscribe(params => {
        this.csrSelectParams = params;
      });

      this.csrJourneys = this.journeySelectService.selectedJourneyIDs ? this.journeySelectService.selectedJourneyIDs : [];
    }

    this.isOutOfBoxExperienceEnabled = this.featureService.checkFlag(FeatureFlags.experience_library);
    this.journeyListUtils.isOutOfBoxExperienceEnabled = this.isOutOfBoxExperienceEnabled;


    if (this.isOutOfBoxExperienceEnabled) {
      const expLibSearchFields = this.buildExperienceLibraryFields();
      this.searchableFields.push(...expLibSearchFields);
    }

    this.ordering = new Ordering('updated_at', 2);
    this.getJourneys();
  }

  onRequestSearch(searchCriteria: SearchCriteria) {
    this.searchCriteria = searchCriteria;
    if (this.searchCriteria.searchFields.length === 0) {
      this.pageManager = new PageManager();
      this.journeys = [];
      this.offset = 0;
    } else {
      this.reloadJourneys();
    }
  }

  /***
   * This block will expand the first/top experience and
   * does not run again until refresh
   ***/
  expandFirstJourney() {
    if (Object.keys(this.displayJourney).length === 0) {
      const journeyToOpen = this.journeys[0];
      if (journeyToOpen) {
        this.displayJourney = {};
        this.displayJourney[journeyToOpen.id] = true;

        if (journeyToOpen.live && journeyToOpen.draft) {
          this.selectedVersion = ContentInstance.live;
        }
      }
    }
  }

  onClearSearch() {
    this.searchCriteria = undefined;
    this.reloadJourneys();
  }

  isCopyList(): boolean {
    let value = false;

    if (this.selectionType !== null) {
      value = true;
    }

    return value;
  }

  onColHeaderClick(column: string) {
    this.unsubscribeAllPending();
    this.onColumnHeadingClick(column);
  }

  unsubscribeAllPending() {
    _.forEach(this.subscriptionArray, sub => {
      sub.unsubscribe();
    });

    this.subscriptionArray = [];
  }

  showArchivedJourneys(): void {
    this.showOptions = false;
    this.reloadJourneys();
  }

  reloadJourneys() {
    this.loaded = false;
    this.pageManager = new PageManager();
    this.journeys = [];
    this.offset = 0;
    this.getJourneys();
  }

  private getAllJourneys(searchCriteria?: SearchCriteria, ordering?: Ordering, liveOnly?: boolean, hideMask: boolean = true, altClientId?: string): Observable<Journey[]> {
    const result = new Observable<Array<Journey>>(subscriber => {
      const limit = 100;
      let offset = 0;
      const allJourneys = new Array<Journey>();
      const processNextJourneyBatch = (journeys: Array<Journey>) => {
        if (journeys.length > 0) {
          journeys.forEach(asset => {
            allJourneys.push(asset);
          });

          offset += limit;

          this.journeyService.getJourneyList(limit, offset, searchCriteria, ordering, liveOnly, hideMask, altClientId).subscribe(
            b => processNextJourneyBatch(b),
            error => subscriber.error(error)
          );
        } else {
          subscriber.next(allJourneys);
          subscriber.complete();
        }
      };

      this.journeyService.getJourneyList(limit, offset, searchCriteria, ordering, liveOnly, hideMask, altClientId).subscribe(
        journeys => processNextJourneyBatch(journeys),
        error => subscriber.error(error)
      );
    });
    return result;
  }

  getJourneys() {
    this.loaded = false;
    const offset = this.offset;
    const altClientId = this.selectionType === 'CSR' ? this.csrSelectParams.clientId : undefined;
    if (!this.searchCriteria) {
      this.searchCriteria = new SearchCriteria();
    }
    this.searchCriteria.filtersIfFieldExists = this.searchCriteria.filtersIfFieldExists.filter((field) => field.fieldName !== 'deleted');
    if (!this.showArchived) {
      this.searchCriteria.filtersIfFieldExists.push(new Filter('deleted', 'false'));
    }

    let newSub: Subscription;
    if (this.csrFilter) {
      newSub = this.getAllJourneys(this.searchCriteria, this.ordering, this.launcherMode || (this.selectionType === 'launch') || (this.selectionType === 'CSR'), true, altClientId).subscribe(
        journeys => {
          this.loaded = true;
          this.filterCsrMsgOnly(offset, journeys);
          this.expandFirstJourney();
        },
        () => {
          this.messageDialog.showMessage('Ooops...Could not load experience list.');
          this.loaded = true;
        }
      );
    } else {
      newSub = this.journeyService.getJourneyList(this.limit, this.offset, this.searchCriteria, this.ordering, this.launcherMode || (this.selectionType === 'launch') || (this.selectionType === 'CSR'), true, altClientId).subscribe(
        journeys => {
          this.loaded = true;
          this.flatten(offset, journeys);
          this.expandFirstJourney();
        },
        () => {
          this.messageDialog.showMessage('Ooops...Could not load experience list.');
          this.loaded = true;
        });
    }

    this.subscriptionArray.push(newSub);
  }

  onScrollDown() {
    // we don't support infinite scroll for CSR-filtered experience lists
    if (this.csrFilter) {
      return;
    }

    this.offset += this.limit;
    this.getJourneys();
  }

  toggleVersionSibling(instance: ContentInstance) {
    this.selectedVersion = instance;
    if (this.selectedVersion === ContentInstance.draft) {
      this.liveSummary.toggleVersion();
    } else {
      this.draftSummary.toggleVersion();
    }
  }

  onRowClick(journeyId: string) {
    this.selectedVersion = undefined;
    if (this.displayJourney[journeyId]) {
      this.displayJourney[journeyId] = false;
    } else {
      this.displayJourney = {};
      this.displayJourney[journeyId] = true;

      const selectedJourney = this.journeys.find(journey => journey.id === journeyId);
      if (selectedJourney && selectedJourney.live && selectedJourney.draft) {
        this.selectedVersion = ContentInstance.live;
      }
    }
  }

  confirmMultiSelect(): void {
    this.journeySelectService.toggleInitOn();
    this.journeySelectService.setSelectedJourneyIDs(this.csrJourneys);
    this.journeySelectService.finalizeSelections();
    this.csrJourneysModalService.close();
  }

  copyJourney(journey: Journey) {
    this.journeyService.copyJourney(journey).subscribe(
      response => {
        const route = 'cx-builder/experience-builder/' + response;
        this.router.navigateByUrl(route);
      },
      error => {
        if (error.status !== 401) {
          this.messageDialog.showMessage('Could not copy experience: ' + error.response);
        }
      }
    );
  }

  exportedJourneyErrored({journey, errorMessage}: { journey: Journey, errorMessage: string }) {
    this.messageDialog.showMessage(`Failed to export experience with id ${journey.id}. Error: ${errorMessage}. Please contact Client Services.`);
  }

  fetchData() {
    this.pageManager = new PageManager();
    this.journeys = [];
    this.loaded = false;
    this.offset = 0;
    this.getJourneys();
  }

  cancelAndClearSelectService(): void {
    if (this.journeySelectService.init) {
      this.journeySelectService.init = false;
      this.journeySelectService.clearJourneys();
    }

    if (this.showCheckboxes) {
      this.router.navigateByUrl('client/' + this.csrSelectParams.clientId + '/edit/onboarding');
    } else {
      this.fallbackJourneySelected.emit(undefined);
    }

    this.csrJourneysModalService.close();
  }

  updateCheckedJourneysArray(e: any): void {
    if (!this.journeySelectService.init) {
      this.journeySelectService.toggleInitOn();
    }
    if (e.action === 'add') {
      this.csrJourneys = _.concat(this.csrJourneys, e.journeyID);
    } else if (e.action === 'remove') {
      this.csrJourneys = _.pull(this.csrJourneys, e.journeyID);
    }
  }

  handleMessageView(): void {
    this.showGrid ? this.messageView = 'normal' : this.messageView = 'grid';
  }

  journeyVersionsAreExpanded(journeyRecord): boolean {
    return this.displayJourney[journeyRecord.id];
  }

  journeyHasDualVersions(journeyRecord): boolean {
    return journeyRecord.live && journeyRecord.draft;
  }

  suppressDrafts(): boolean {
    return this.selectionType === 'CSR' || this.launcherMode;
  }

  selectFallbackJourney(journeyInfo: FallbackJourneyInfo): void {
    this.fallbackJourneySelected.emit(journeyInfo);
  }

  clearAllJourneys(): void {
    this.journeySelectService.clearJourneys();
    this.csrJourneys = [];
  }

  preferredVersion(journeyRecord: Journey): string {
    return _.isEmpty(journeyRecord.live) ? 'draft' : 'live';
  }

  journeyIsChecked(journeyRecord: Journey): boolean {
    return this.journeySelectService.journeyIsSelected(journeyRecord.id);
  }

  navigateBack() {
    this.location.back();
  }

  private buildExperienceLibraryFields(): SearchableField[] {
    return [
      new SearchableField('Outcome', 'outcome_name'),
      new SearchableField('Outcome (Message)', 'components.outcome_name'),
      new SearchableField('Experience Type (Message)', 'components.experience_type_name'),
      new SearchableField('Experience Type', 'experience_type_name')
    ];
  }

  private flatten(offset: number, journeys: Journey[]): void {
    this.pageManager.addPage(offset, journeys);
    this.journeys = this.pageManager.flattenPages();
  }

  private filterCsrMsgOnly(offset: number, journeys: Journey[]): void {
    this.clientService.getClient(localStorage.getItem('current_client'), true)
      .subscribe(client => {
        this.client = client;
        journeys = journeys.filter(j => _.includes(this.client.onboarding.csr.eligible_triggers, j.id));
        this.flatten(offset, journeys);
      });
  }
}
